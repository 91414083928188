$(document).ready(function () {

  runStickyHeader();
  runMenuTrigger();
  runTestimonialsSlider();
  runWorksSlider();
  runGallery();
  runMobileMenu();
  runAnchorScroll();
  runParallax();

});

function runStickyHeader() {

  var header = document.querySelector('header');
  var headerOffset = header.offsetTop;

  window.addEventListener('scroll', function(e) {
    requestAnimationFrame(function() {
      if (window.pageYOffset > headerOffset) {
        header.classList.add('sticky');
      }
      else {
        header.classList.remove('sticky');
      }
    });
  });

}

function runMenuTrigger() {

  var lastId;
  var menu = $('.header__menu');
  var menuHeight = menu.outerHeight();
  var menuItems = menu.find('a');
  var scrollItems = menuItems.map(function() {
    var item = $($(this).attr('href'));
    if (item.length) {
      return item;
    }
  });

  menuItems.click(function(e) {
    var href = $(this).attr('href');
    var offsetTop = href === '#' ? 0 : $(href).offset().top;
    $('html, body').stop().animate({
      scrollTop: offsetTop
    }, 600);
    e.preventDefault();
  });

  $(window).scroll(function() {
    var fromTop = $(this).scrollTop()+menuHeight;
    var cur = scrollItems.map(function() {
      if ($(this).offset().top < fromTop)
      return this;
    });
    cur = cur[cur.length-1];
    var id = cur && cur.length ? cur[0].id : '';
    if (lastId !== id) {
      lastId = id;
      menuItems.parent().removeClass('current').end().filter('[href="#'+id+'"]').parent().addClass('current');
    }
  });

}

function runTestimonialsSlider() {

  new Swiper ('.testimonials__slider', {
    loop: true,
    speed: 400,
    autoHeight: true,
    navigation: {
      prevEl: '.testimonials__nav-prev',
      nextEl: '.testimonials__nav-next'
    }
  });

}

function runWorksSlider() {

  new Swiper ('.our-works__slider', {
    loop: true,
    speed: 400,
    autoHeight: true,
    navigation: {
      prevEl: '.our-works__nav-prev',
      nextEl: '.our-works__nav-next'
    }
  });

}

function runGallery() {

  new SimpleLightbox('a.gallery', {
    captions: false,
    showCounter: false,
    disableScroll: false
  });

}

function runMobileMenu() {

  $('.menu-trigger').click(function() {
    $(this).toggleClass('open');
    $('.menu').toggleClass('show');
  });
  $('.menu ul li a').click(function() {
    $('.menu-trigger').removeClass('open');
    $('.menu').removeClass('show');
  });

}

function runAnchorScroll() {

  $('.scroll-init').on('click',function(e) {
		e.preventDefault();
		$('html, body').animate({
			scrollTop: $($(this).attr('href')).offset().top,
		}, 600);
	});

}

function runParallax() {

  $(window).enllax();

}
